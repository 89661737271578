/* eslint-env browser */

const OVERLAY_URL = '//engage-cdn.schibsted.media/js/overlay/latest/overlay.min.js';


function loadOverlay(providerId, contentId) {
  window.loadOverlayToolCallback = (data) => {
    if (!data.jwt) {
      return;
    }

    const el2 = document.createElement('script');
    el2.src = OVERLAY_URL;
    el2.type = 'text/javascript';
    el2.onload = () => {
      /* eslint no-new: 0, no-undef: 0 */
      new window.EngageOverlay({
        site: data.newsroom,
        jwt: data.jwt,
        userId: data.userId,
        articleId: contentId
      });
    };

    document.body.appendChild(el2);
  };

  const el = document.createElement('script');
  el.src = `https://engage-auth.schibsted.media/jwt/${encodeURIComponent(providerId)}?callback=loadOverlayToolCallback`;
  el.type = 'text/javascript';
  document.body.appendChild(el);
}

export default class EngageOverlayLoader {
  constructor(tracker) {
    this.tracker = tracker;
    this.loadIfNeeded();
  }

  loadIfNeeded() {
    this.tracker.evaluateEventInputs('trackerEvent').then((eventInput) => {
      const {
        provider,
        object,
        device
      } = eventInput;

      if (object.type === 'Article' && device.deviceType !== 'Mobile') {
        loadOverlay(provider['spt:engage'] || provider.id, object.id);
      }
    });
  }
}
